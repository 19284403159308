.section-community
	.content
		width: 100%
		padding: 7.125rem 0

		.text
			padding: 0 1.875rem
			max-width: 700px

	@media screen and ( max-width: 768px )
		.content
			.text
				br
					display: none
