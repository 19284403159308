.section-about
	.content
		padding: 7.125rem 0

	.left-text
		width: 45%

	.right-collector
		width: 51%

		.list-item
			gap: 1.25rem

		.item
			width: 31%
			padding-top: 30%
			background-repeat: no-repeat !important
			background-size: cover !important
			background-position: 50% 50% !important
			transition: all .3s ease-in-out
			border-radius: 2.5rem
			cursor: pointer
			overflow: hidden

			&:hover
				box-shadow: inset 0px 0px 0px 0.625rem var(--red-pink)

			&.item-1
				background: url('/images/about/1.jpg')

			&.item-2
				background: url('/images/about/2.jpg')

			&.item-3
				background: url('/images/about/3.jpg')

			&.item-4
				background: url('/images/about/11.jpg')

			&.item-5
				background: url('/images/about/4.jpg')

			&.item-6
				background: url('/images/about/12.jpg')

	@media screen and ( max-width: 1200px )
		.content
			flex-direction: column

			.left-text, .right-collector
				width: 100%

			.left-text
				margin-bottom: 3.125rem

			.right-collector
				.list-item
					gap: 2.5rem

	@media screen and ( max-width: 768px )
		.content
			.right-collector
				.list-item
					.item
						width: 40%
						padding-top: 39%

	@media screen and ( max-width: 640px )
		.content
			.right-collector
				.list-item
					.item
						width: 100%
						padding-top: 97%
