.message-claim-box
  position: fixed
  padding: 1.25rem
  bottom: 1.25rem
  right: 1.25rem
  min-width: 25rem
  max-width: 25rem
  line-height: 1.5
  border-radius: 5px
  text-transform: capitalize
  word-break: break-word
  background: rgb(72,72,255)
  background: linear-gradient(71deg, rgba(72,72,255,1) 0%, rgba(188,39,237,1) 100%)
  z-index: 999

  &.hide
    display: none

  a
    display: block
    text-decoration: underline

  .txn
    color: var(--white)

  .close-message
    position: absolute
    top: 0.625rem
    right: 1rem
    cursor: pointer

    path
      fill: var(--violet)
