@import "../../styles/variables"

.header
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 10

  .header-top
    display: flex
    justify-content: space-between
    align-items: center
    width: 94%
    height: 7.75rem
    max-width: 1920px
    margin: 0 auto

    .logo
      min-width: 23rem
      max-width: 25rem

    .social-menu
      gap: 1.25rem
      list-style: none

      li
        list-style: none
        a
          display: block
          max-width: 3.8125rem
          svg
            height: 3rem
            path
              fill: white

    .btn-connect-wallet
      margin-left: 1.25rem

    .member-area
      color: var(--white)
      margin-left: 1.25rem

      svg
        font-size: 25px

    .mobile-trigger
      display: none

    .menu
      display: flex
      align-items: center
      list-style: none

      a
        color: var(--white)

      a,
      span
        font-family: "Montserrat-Bold", sans-serif
        font-size: 2rem
        cursor: pointer
        padding: 0 1.562rem
        text-align: center
        display: inline-block

      .download-mobile
        display: none

      .menu-item
        text-align: center
        z-index: 2
        list-style: none

        .menu-dropdown
          left: 50%
          top: 0
          transform: translateX(-50%)
          padding-top: 2rem
          z-index: 1
          display: none

          .menu-item
            margin-bottom: 0.625rem

        &.menu-dropdown-parent
          &:hover
            .menu-dropdown
              display: block

  @media screen and (min-width: 769px)
    .header-top
      .dddb-member-mobile
        display: none

  @media screen and (max-width: 768px)
    .header-top
      width: 100%
      padding: 10px 25px
      position: fixed
      top: 0
      left: 0
      z-index: 500
      background: var(--red-pink)

      .social-menu
        li
          margin: 0 1rem

      .btn-connect-wallet
        display: none

      .member-area
        display: none

      .left-box
        position: relative
        z-index: 100

        img
          max-width: 160px

      .mobile-trigger
        text-align: center
        display: inline-block
        transition: all 0.3s ease-in-out
        position: relative
        width: 25px
        height: 25px
        cursor: pointer
        z-index: 100
        img
          max-width: 100%
          filter: invert(1)
          position: absolute
          width: 100%
          left: 0
          top: 0

          &.close-menu
            width: 15px
            left: 5px
            top: 5px
            opacity: 0

        &.open
          transform: rotateZ(270deg)
          .open-menu
            opacity: 0

          .close-menu
            opacity: 1

      .menu
        position: fixed
        left: 0
        right: 0
        top: 0
        width: auto
        height: 100vh
        padding-top: 150px
        text-align: center
        z-index: 10
        flex-direction: column
        transform: translateX(-100%)
        transition: all 0.3s ease-in-out
        will-change: transition

        .download-mobile
          display: block

        .menu-item
          margin-bottom: 30px
          &.hide
            display: none

          a
            margin-left: 0

          .menu-dropdown
            padding-top: 4rem
            .menu-item
              margin-bottom: 1.875rem

        &.show
          transform: translateX(0)
          background: var(--violet)
          z-index: 10

  @media screen and (max-width: 680px)
    .header-top
      padding: 10px
      .menu
        .btn-connect-wallet
          margin-top: 0
          margin-left: 0
          max-width: 240px

  @media screen and (max-width: 480px)
    .header-top
      .logo
        min-width: 17rem

      .social-menu

        li
          a
            display: block
            max-width: 3rem
