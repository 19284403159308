.section-top
	background: url("/images/banner-top.png") no-repeat
	background-size: cover
	background-position: top center
	margin-top: 7.7rem

	.content
		min-height: 100vh
		@media screen and ( max-width: 640px )
			min-height: 65vh
			a
				text-align: center
				img
					max-width: 50%

	.msg-claim-over
		font-size: 20px
		margin: 10px 0

	.link-download
		margin-top: 8px
		font-size: 34px

	.join-our
		border-radius: 4rem
		margin-bottom: 0.5rem

	.text-banner
		max-width: 55vw
		top: 16vh
		position: absolute

	.buy-dddb
		margin-bottom: 3.125rem

		.number-left
			margin-bottom: 1rem
			font-size: 34px

		.input-amount
			width: 100px
			min-height: 45px
			font-size: 18px
			color: var(--red-pink)
			padding: 0.625rem
			text-align: center
			border: 2px solid var(--red-pink)
			border-radius: 3.125rem

		.btn-mint
			width: 220px
			height: 50px
			font-size: 18px
			min-width: auto
			max-width: initial

	@media screen and ( max-width: 640px )
		.buy-dddb
			.number-left
				font-size: 20px

			.btn-mint
				width: 150px
				height: 40px
				font-size: 16px
				margin: 5px 0

			.link-download
				font-size: 20px

		.msg-claim-over
			font-size: 13px
			margin: 5px 0

	@media screen and ( max-width: 375px )
		.buy-dddb
			.number-left
				font-size: 15px

			.input-amount
				width: 85px
				min-height: 30px

			.btn-mint
				width: 135px
				height: 37px
				margin: 0

			.link-download
				font-size: 15px
