.section-roadmap
	.roadmap
		padding: 7.125rem 0

	.timeline
		position: relative

		&::before
			content: ""
			width: 0.625rem
			height: 90%
			background: var(--red-pink)
			position: absolute
			left: 14%
			top: 0
			z-index: 1

		.item
			position: relative
			z-index: 2

			p
				max-width: 40rem

		.percent
			width: 17rem
			padding-top: 27%
			background-repeat: no-repeat !important
			background-size: cover !important
			background-position: 50% 50% !important
			border-radius: 50%
			overflow: hidden

			&.p-1
				background: var(--yellow) url('/images/roadmap/10-percent.png')

			&.p-2
				background: var(--yellow) url('/images/roadmap/30-percent.png')

			&.p-3
				background: var(--yellow) url('/images/roadmap/70-percent.png')

			&.p-4
				background: var(--yellow) url('/images/roadmap/100-percent.png')

		.desc
			margin-left: 5rem
