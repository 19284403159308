.member-page
	.content
		min-height: 100vh
		padding-top: 7.75rem
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center

		.please-buy
			color: var(--violet) !important
			text-decoration: underline

		.enter-mt4
			gap: 1.562rem

			input
				width: 300px
				border: 2px solid var(--red-pink)
				border-radius: 30px
				padding: 10px 20px
				font-size: 30px
				text-align: center

			.btn-enter
				font-size: 20px
				text-transform: uppercase
				min-width: 200px
				height: 50px

		.btn-download-mt4
			min-width: auto
			max-width: initial
			width: 250px
			font-size: 20px
			height: 50px
			margin-bottom: 20px

		.discord-vip
			font-size: 18px
			text-decoration: underline
			color: var(--red-pink) !important
