.section-featured
	.items-properties
		padding: 7.125rem 0

	.list-items-properties
		width: 100%
		gap: 1.562rem

		.item
			width: 48%
			grid-gap: 1.562rem

			.item-img
				width: 39%
				padding-top: 33%
				background-repeat: no-repeat !important
				background-size: cover !important
				background-position: 50% 50% !important
				border-radius: 2.5rem

				&.p-1
					background: url('/images/featured/7.jpg')

				&.p-2
					background: url('/images/featured/60.jpg')

				&.p-3
					background: url('/images/featured/100.jpg')

				&.p-4
					background: url('/images/featured/84.jpg')

			.box
				width: 73%

			.properties
				gap: 1.25rem

				.properties-item
					width: 47%

					p
						padding: 0.312rem 1.25rem
						border-radius: 1.25rem
						box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px 0px inset

	.dddb-slider
		overflow: hidden

		img
			border-radius: 2.5rem

	.swiper-container
		padding-bottom: 5rem

	.swiper-pagination-bullet
		width: 1.562rem
		height: 1.562rem
		background: var(--white)
		opacity: 0.7

	.swiper-pagination-bullet-active
		background: var(--red-pink)
		opacity: 1

	@media screen and (max-width: 640px)
		.list-items-properties
			.item
				width: 100%
