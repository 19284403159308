.section-team
	.team-members
		padding: 7.125rem 0

	.list-members
		width: 100%
		gap: 1.562rem

		.member
			width: 48%
			grid-gap: 1.562rem

			.member-img
				width: 39%
				padding-top: 33%
				background-repeat: no-repeat !important
				background-size: cover !important
				background-position: 50% 50% !important
				transition: all 0.3s ease-in-out
				border-radius: 2.5rem
				cursor: pointer
				overflow: hidden

				&.p-1
					background: url('/images/team/0.jpg')
					&:hover
						background: url('/images/team/dddb-1-hover.png')

				&.p-2
					background: url('/images/team/38.jpg')
					&:hover
						background: url('/images/team/dddb-2-hover.png')

				&.p-3
					background: url('/images/team/73.jpg')
					&:hover
						background: url('/images/team/dddb-3-hover.png')

				&.p-4
					background: url('/images/team/43.jpg')
					transition: none
					&:hover
						background: url('/images/team/dddb-4-hover.png')
						background-size: contain !important
				&.p-5
					background: url('/images/team/115.jpg')
					&:hover
						background: url('/images/team/dddb-5-hover.jpeg')

			.box
				width: 73%

			.properties
				gap: 1.25rem

				.properties-item
					width: 47%

					p
						padding: 0.312rem 1.25rem
						border-radius: 1.25rem
						box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px 0px inset

	@media screen and (max-width: 640px)
		.team-members
			display: unset

			.title
				margin-top: 5rem

		.list-members
			padding-bottom: 4rem

			.member
				width: 100%
				display: unset

				.member-img
					width: 90%
					padding-top: 87%
					margin: 3rem auto
				
				.box
					width: 100%

				.desc
					p
						font-size: 2rem
