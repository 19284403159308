.footer
  min-height: 7.5rem
  background: rgb(188,39,237)
  background: linear-gradient(61deg, rgba(188,39,237,1) 0%, rgba(214,42,177,1) 52%, rgba(236,44,125,1) 100%)

  .content
    gap: 3.125rem

    .logo
      min-width: 23rem
      max-width: 34.375rem

  .footer-menu
    gap: 2.5rem
    list-style: none

    a
      color: var(--white)
      display: flex

  @media screen and ( max-width: 1600px )
    .footer-menu
      gap: 2rem

    a
      font-size: 1.562rem

  @media screen and ( max-width: 640px )
    .container
      padding: 2rem 0.937rem

      .content
        display: unset
        gap: 2.5rem

      .footer-menu
        margin-top: 1rem
        flex-wrap: wrap

