@font-face
  font-family: "AvenirNext-Regular"
  src: local('AvenirNext-Regular'), url(../fonts/AvenirNext-Regular.ttf) format('ttf'), url(../fonts/AvenirNext-Regular.woff) format('woff'), url(../fonts/AvenirNext-Regular.woff2) format('woff2')

@font-face
  font-family: "AvenirNextCondensed-Regular"
  src: local('AvenirNextCondensed-Regular'), url(../fonts/AvenirNextCondensed-Regular.ttf) format('ttf'), url(../fonts/AvenirNextCondensed-Regular.woff) format('woff'), url(../fonts/AvenirNextCondensed-Regular.woff2) format('woff2')

@font-face
  font-family: "HelveticaNeue"
  src: local('HelveticaNeue'), url(../fonts/HelveticaNeue.ttf) format('ttf'), url(../fonts/HelveticaNeue.woff) format('woff'), url(../fonts/HelveticaNeue.woff2) format('woff2')

@font-face
  font-family: "Montserrat-Black"
  src: local('Montserrat-Black'), url(../fonts/Montserrat-Black.ttf) format('ttf'), url(../fonts/Montserrat-Black.woff) format('woff'), url(../fonts/Montserrat-Black.woff2) format('woff2')

@font-face
  font-family: "Montserrat-Bold"
  src: local('Montserrat-Bold'), url(../fonts/Montserrat-Bold.ttf) format('ttf'), url(../fonts/Montserrat-Bold.woff) format('woff'), url(../fonts/Montserrat-Bold.woff2) format('woff2')

@font-face
  font-family: "Montserrat-Medium"
  src: local('Montserrat-Medium'), url(../fonts/Montserrat-Medium.ttf) format('ttf'), url(../fonts/Montserrat-Medium.woff) format('woff'), url(../fonts/Montserrat-Medium.woff2) format('woff2')

@font-face
  font-family: "Montserrat-Regular"
  src: local('Montserrat-Regular'), url(../fonts/Montserrat-Regular.ttf) format('ttf'), url(../fonts/Montserrat-Regular.woff) format('woff'), url(../fonts/Montserrat-Regular.woff2) format('woff2')

@font-face
  font-family: "Montserrat-SemiBold"
  src: local('Montserrat-SemiBold'), url(../fonts/Montserrat-SemiBold.ttf) format('ttf'), url(../fonts/Montserrat-SemiBold.woff) format('woff'), url(../fonts/Montserrat-SemiBold.woff2) format('woff2')

@font-face
  font-family: "Montserrat-ExtraBold"
  src: local('Montserrat-ExtraBold'), url(../fonts/Montserrat-ExtraBold.ttf) format('ttf'), url(../fonts/Montserrat-ExtraBold.woff) format('woff'), url(../fonts/Montserrat-ExtraBold.woff2) format('woff2')

*
  margin: 0
  padding: 0
  font-size: 1rem
  font-family: "Arial", sans-serif
  box-sizing: border-box

html
  scroll-behavior: smooth
  font-size: 16px

  @media screen and ( max-width: 1600px )
    font-size: 13px

  @media screen and ( max-width: 1400px )
    font-size: 11px

  @media screen and ( max-width: 1300px )
    font-size: 10px

  @media screen and ( max-width: 1200px )
    font-size: 9px
  
  @media screen and ( max-width: 992px )
    font-size: 7px

body
  background: #FFC601 url("/images/bg-body.png") repeat-y
  background-position: top center
  background-size: cover

body::-webkit-scrollbar
  width: 7px
  height: 7px

body::-webkit-scrollbar-track
  background-color: var(--grey)
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)

body::-webkit-scrollbar-thumb
  background: linear-gradient(71deg, rgba(72,72,255,1) 0%, rgba(188,39,237,1) 100%)

a
  text-decoration: none

  &:hover
    color: var(--yellow) !important

img
  max-width: 100%

button
  outline: none
  border: none
  cursor: pointer

input
  width: 100%
  &:focus
    outline: none
    box-shadow: none
    border: 2px solid var(--violet)
