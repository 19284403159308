.section-dddb
	.content
		width: 100%
		padding: 7.125rem 0
		color: var(--white)s
		z-index: 2

		.left-image
			width: 30%

		.about
			width: 69%
	
	@media screen and (max-width: 640px)
		.content
			display: unset
		
			.left-image
				width: 50%
				margin: auto
				padding: 7.125rem 0 5rem 0

			.about
				width: 100%
				padding-bottom: 7.125rem
