// font-size
.fsz-1
  font-size: 1.1875rem

.fsz-2
  font-size: 1.25rem

.fsz-3
  font-size: 1.3125rem // 21px

.fsz-4
  font-size: 1.5625rem // 25px

.fsz-5
  font-size: 1.625rem // 26px

.fsz-6
  font-size: 1.687rem // 27px

.fsz-7
  font-size: 1.812rem // 29px

.fsz-8
  font-size: 1.875rem // 30px

.fsz-9
  font-size: 2rem

.fsz-10
  font-size: 2.062rem // 33px

.fsz-11
  font-size: 2.187rem

.fsz-12
  font-size: 2.437rem // 39px

.fsz-13
  font-size: 2.5rem

.fsz-14
  font-size: 2.75rem // 44px

.fsz-15
  font-size: 3.5rem // 56px

.fsz-16
  font-size: 4.375rem // 70px

.fsz-17
  font-size: 4.812rem // 77px

.fsz-18
  font-size: 5.625rem

.fsz-19
  font-size: 6.125rem

.fsz-20
  font-size: 8.125rem

// line-height
.line-height-1
  line-height: 2.125rem

// font-family
.font-avenir-next-regular
  font-family: "AvenirNext-Regular", sans-serif

.font-avenir-next-condensed-regular
  font-family: "AvenirNextCondensed-Regular", sans-serif

.font-helvetica-neue
  font-family: "HelveticaNeue", sans-serif

.font-montserrat-black
  font-family: "Montserrat-Black", sans-serif

.font-montserrat-bold
  font-family: "Montserrat-Bold", sans-serif

.font-montserrat-extra-bold
  font-family: "Montserrat-ExtraBold", sans-serif

.font-montserrat-semi-bold
  font-family: "Montserrat-SemiBold", sans-serif

.font-montserrat-meidum
  font-family: "Montserrat-Medium", sans-serif

.font-montserrat-regular
  font-family: "Montserrat-Regular", sans-serif

// typography
.text-left
  text-align: left

.text-center
  text-align: center

.text-justify
  text-align: justify

.text-left
  text-align: left

.text-capitalize
  text-transform: capitalize

.text-shadow
  text-shadow: 0px 0px 5px #a8a6a6

//text-color

.text-transparent
  width: 0
  height: 0
  color: transparent
  opacity: 0

// color
.color-white
  color: var(--white)

.color-black
  color: var(--black)

.color-black-border
  color: var(--black-border)

.color-yellow
  color: var(--yellow)

.color-grey
  color: var(--grey)

.color-dove-grey
  color: var(--dove-grey)

.color-violet
  color: var(--violet)

.color-red-pink
  color: var(--red-pink)

// background
.bg-white
  background: var(--white)

.bg-black
  background: var(--black)

.bg-black-border
  background: var(--black-border)

.bg-yellow
  background: var(--yellow)

.bg-grey
  background: var(--grey)

.bg-violet
  background: var(--violet)

.bg-red-pink
  background: var(--red-pink)

// container
.container
  width: 90%
  max-width: 1920px
  padding: 0 0.937rem
  margin: 0 auto

// class
.truncate
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap

.full-page
  width: 100%
  min-height: 100vh
  margin-top: -2px
  overflow: hidden

  @media screen and ( max-width: 1280px )
    min-height: auto

.d-block
  display: block

// flex
.d-flex
  display: flex

.flex-column
  flex-direction: column

.flex-wrap
  flex-wrap: wrap

.justify-content-center
  justify-content: center

.justify-content-space-between
  justify-content: space-between

.justify-content-space-evenly
  justify-content: space-evenly

.align-items-center
  align-items: center

.align-items-start
  align-items: flex-start

.align-items-unset
  align-items: unset

// button
.btn
  display: inline-block
  min-width: 17.625rem
  max-width: 17.625rem
  min-height: 3.25rem
  color: var(--white)
  font-family: "Montserrat-Bold", sans-serif
  border-radius: 3.125rem
  overflow: hidden

  &:disabled
    opacity: 0.5
    cursor: not-allowed

.btn-danger
  color: var(--violet)
  font-size: 1.65rem
  background: var(--yellow)
  padding: 1.25rem

.btn-gradient
  min-width: 17.625rem
  max-width: 17.625rem
  height: 3.25rem
  color: var(--white)
  background: rgb(72,72,255)
  background: linear-gradient(71deg, rgba(72,72,255,1) 0%, rgba(188,39,237,1) 100%)
  border-radius: 3.125rem

  &:hover
    background: rgb(188,39,237)
    background: linear-gradient(192deg, rgba(188,39,237,1) 0%, rgba(72,72,255,1) 100%)

  &:disabled
    opacity: 0.7
    cursor: not-allowed
    background: var(--dove-grey)

// spacing
.mb-1
  margin-bottom: 0.625rem // 10px

.mb-2
  margin-bottom: 1.25rem // 20px

.mb-3
  margin-bottom: 1.875rem // 30px

.mb-4
  margin-bottom: 2.5rem // 40px

.mb-5
  margin-bottom: 3.125rem // 50px

.mb-6
  margin-bottom: 3.75rem // 60px

.mb-7
  margin-bottom: 4.375rem // 70px

.mb-8
  margin-bottom: 5rem // 80px

.me-auto
  margin: 0 auto

// position
.position-relative
  position: relative

.position-absolute
  position: absolute

// hidden
.hidden
  display: none !important

.back-to-top
  position: fixed
  width: 45px
  height: 45px
  right: 20px
  bottom: 20px
  cursor: pointer
  visibility: hidden
  opacity: 0

  &.show
    visibility: visible
    opacity: 1

.cursor-pointer
  cursor: pointer

.Toastify
  &__toast-body
    *
      color: var(--black)
      font-size: 14px
      font-family: "Montserrat-SemiBold", sans-serif
