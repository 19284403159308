.section-faq
	.faq-text-mark
		top: -24%
		left: 0
		z-index: 1

	.content
		width: 100%
		padding: 7.125rem 0
		color: var(--white)
		position: relative
		z-index: 2

		.title-header
			.sub-title
				display: inline-block
				margin-top: -3rem

		.questions-menu
			color: var(--white)
			width: 34%
			display: none

			li
				&:hover, &.active
					color: var(--yellow)

		.question-content
			width: 100%
			.list
				max-width: 100%
				margin-bottom: 20px

				.show
					margin-bottom: 10px
					background: var(--red-pink)
					position: relative
					transition: all 0.5s ease-in-out
					max-height: 500px
					

				.none
					transition: all 0.5s ease-in-out
					max-height: 0
					overflow: hidden

				.detail
					padding: 0 15px
					white-space: break-spaces

					ul
						margin-left: 2rem

						li
							margin-left: 2rem
						
					a
						color: #4848ff
						text-decoration: underline

				.title
					cursor: pointer

	@media screen and (max-width: 640px)
		.content

			.title-header

				.title
					font-size: 6rem

				.sub-title
					font-size: 4rem
					margin-top: -2.375rem
